<template>
  <editor-content :editor="editor" />
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import TextAlign from '@tiptap/extension-text-align';


export default {
  components: {
    EditorContent,
  },

  props: {
    editor: {
      default: null,
      type: Object
    },
    value: {
      default: "",
      type: String
    }
  },

  watch: {
    editor: {
      immediate: true,
      handler(editor) {
        if (!editor) return;
        this.editor.commands.setContent(this.value);
        this.editor.on("update", () => {
          this.$emit("input", this.editor.getHTML());
          console.log('this.editor.geddtHTML() :>> ', this.editor.getHTML());

        });
      }
    },
    value: {
      handler(value) {
        const isSame = this.editor.getHTML() === value

        if (isSame) return;


        this.editor.commands.setContent(value, false)
      }
    }
  },
}
</script>