<template>
	<div class="custom-upload">
		<el-upload :accept="MIME[imageTabCopy[0].data_type].string" class="custom-upload__upload" action drag :http-request="onFileChange" :on-preview="handlePictureCardPreview" :on-remove="handleRemove" :file-list="imageTabCopy">
			<i class="el-icon-upload"></i>
			<div class="el-upload__text">
				Déplacez 
				<span v-if="fileParams.fileType === 'image'">une image</span>
				<span v-if="fileParams.fileType === 'video'">une vidéo</span>
				<span v-if="fileParams.fileType === 'audio'">un audio</span>
				 ici ou
				<br />
				<em>cliquez ici pour sélectionner le fichier</em>
			</div>
			<div class="el-upload__tip" slot="tip">
				<span v-if="fileParams.fileType === 'image'">Fichiers jpg/png de moins de 2 Mo</span>
				<span v-if="fileParams.fileType === 'video'">Fichier mp4/webm de moins 40 Mo</span>
				<span v-if="fileParams.fileType === 'audio'">Fichiers mp3/wav de moins de 40 Mo</span>
			</div>
		</el-upload>
		<div class="custom-upload__preview">
			<img v-if="MIME.image.condition.includes(imageTabCopy[0].type)" :src="fileParams.fileBaseUrl + '/'+imageTabCopy[0].url" />
			<video controls v-else-if="MIME.video.condition.includes(imageTabCopy[0].type)" :src="fileParams.fileBaseUrl + '/'+imageTabCopy[0].url" />
			<audio controls v-else-if="MIME.audio.condition.includes(imageTabCopy[0].type)" :src="fileParams.fileBaseUrl + '/'+imageTabCopy[0].url" />
			<svg v-else viewBox="128.501 -17.28 457.114 351.998" width="457.114" height="351.998">
				<clipPath id="v">
					<path d="m145 165h410v224h-410z" />
				</clipPath>
				<clipPath id="w">
					<path d="m346.27 368.07c2.6055-.16797 5.2148-.46484 7.8281-.5 30.961-.5 60.973-6.668 89.887-18.738 34.035-14.223 63.684-35.441 86.824-66.922 2.832-3.8477 2.3906-6.1328-.17969-9.6602-17.398-23.895-38.922-42.102-63.637-55.789-39.199-21.707-80.93-30.781-124.68-30.129-30.52.46484-60.168 5.8281-88.727 17.668-34.461 14.285-64.113 35.906-85.934 69.273-1.8359 2.8242-1.8125 4.6875 0 7.4492 13.816 21.113 31.152 37.91 51.441 51.094 39.23 25.453 82.09 35.516 127.18 36.254zm-1.8594-202.71c55.957.69531 106.04 13.957 151.45 45.555 21.621 15.043 40.188 33.777 54.98 56.855 4.5469 7.0859 4.5938 11.848.078124 18.832-21.609 33.312-50.367 56.887-83.832 73.871-31.605 16.062-64.949 24.723-99.641 27.172-50.742 3.5664-99.449-5.0508-145.12-30.398-30.156-16.742-55.445-39.789-74.008-71.215-3.6953-6.2656-3.6055-12.023.089844-18.262 20.988-35.504 50.332-59.875 85.035-76.961 28.926-14.234 59.355-22.059 90.973-24.289 7.5508-.52734 15.109-.86719 20.004-1.1602z" />
				</clipPath>
				<g fill="#c1c4cb" clip-path="url(#v)" transform="matrix(1, 0, 0, 1, 12.083854, -117.071915)">
					<g clip-path="url(#w)">
						<path d="m19.512 23.402h660.84v506.97h-660.84z" />
					</g>
				</g>
				<path fill="#c1c4cb" d="m126.22 175.16c-5.4102 0-9.8008-4.3906-9.8008-9.8008v-55.766c0-5.4102 4.3906-9.8008 9.8008-9.8008h61.602c5.4102 0 9.8008 4.3906 9.8008 9.8008s-4.3906 9.8008-9.8008 9.8008h-51.801v45.965c0 5.4102-4.3906 9.8008-9.8008 9.8008z" transform="matrix(1, 0, 0, 1, 12.083854, -117.071915)" />
				<path fill="#c1c4cb" d="m563.73 175.16c-5.4141 0-9.8008-4.3906-9.8008-9.8008v-45.965h-51.805c-5.4141 0-9.8008-4.3906-9.8008-9.8008s4.3867-9.8008 9.8008-9.8008h61.605c5.4141 0 9.8008 4.3906 9.8008 9.8008v55.766c0 5.4102-4.3867 9.8008-9.8008 9.8008z" transform="matrix(1, 0, 0, 1, 12.083854, -117.071915)" />
				<path fill="#c1c4cb" d="m187.82 451.79h-61.602c-5.4102 0-9.8008-4.3867-9.8008-9.8008v-55.758c0-5.4141 4.3906-9.8008 9.8008-9.8008s9.8008 4.3867 9.8008 9.8008v45.961h51.801c5.4102 0 9.8008 4.3867 9.8008 9.8008s-4.3867 9.7969-9.8008 9.7969z" transform="matrix(1, 0, 0, 1, 12.083854, -117.071915)" />
				<path fill="#c1c4cb" d="m563.73 451.79h-61.605c-5.4141 0-9.8008-4.3867-9.8008-9.8008s4.3867-9.8008 9.8008-9.8008h51.805v-45.961c0-5.4141 4.3867-9.8008 9.8008-9.8008s9.8008 4.3867 9.8008 9.8008v55.758c0 5.4219-4.3867 9.8047-9.8008 9.8047z" transform="matrix(1, 0, 0, 1, 12.083854, -117.071915)" />
				<path fill="#c1c4cb" d="m346.88 345.75c-39.613 0-71.844-32.227-71.844-71.836s32.227-71.836 71.844-71.836c39.613 0 71.836 32.223 71.836 71.832s-32.223 71.84-71.836 71.84zm0-124.08c-28.805 0-52.242 23.438-52.242 52.238 0 28.801 23.438 52.238 52.242 52.238s52.242-23.438 52.242-52.238c0-28.801-23.438-52.238-52.242-52.238z" transform="matrix(1, 0, 0, 1, 12.083854, -117.071915)" />
			</svg>
		</div>
	</div>
</template>

<script>
import { Button, Upload, Input, Dialog, Select, Option } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
const axios = require('axios');

export default {
	name: 'HomePageEdit',
	props: ['fileParams'],
	components: {
		'el-button': Button,
		'el-upload': Upload,
		'el-input': Input,
		'el-select': Select,
		'el-dialog': Dialog,
		'el-option': Option,
	},
	data() {
		return {
			nomMusee: '',
			isUploaded: false,
			MIME: {
				video: {
					condition: ['video/mp4', 'video/webm', 'video/ogg'],
					string: 'video/mp4, video/webm, video/ogg',
				},
				image: {
					condition: ['image/jpeg', 'image/jpg', 'image/webp', 'image/png', 'image/gif'],
					string: 'image/png, image/jpeg, image/webp, image/gif',
				},
				audio: {
					condition: ['audio/mpeg', 'audio/wav'],
					string: 'audio/mpeg, audio/wav',
				},
			},
		};
	},
	computed: {
		imageTabCopy() {
			return Array.isArray(this.fileParams.fileNodeValue) ? this.fileParams.fileNodeValue : [this.fileParams.fileNodeValue];
		},
	},
	methods: {
		log(o) { console.log(o) },
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		handleRemove(file, imageTabCopy) {
			console.log(file, imageTabCopy);
		},
		onFileChange: function (e) {
			var fsize = e.file.size / 1024;

			if (this.MIME.image.condition.includes(e.file.type) && fsize > 2242) {
				alert('La taille du fichier envoyé est ' + fsize.toFixed(2) + "KB. Max 2000KB ");
				return false;
			} else if (this.MIME.video.condition.includes(e.file.type) && fsize > 52242) {
				alert('La taille du fichier envoyé est ' + fsize.toFixed(2) + "KB. Max 40000KB ");
				return false;
			}

			this.uploadFile(this.fileParams.siteID, e.file, 1920, 1080, 'imageAccueil');
			this.isUploaded = false;
		},
		uploadFile: function (pSiteID, pFile, pWidth, pHeight, pModule) {
			let self = this;

			let fd = new FormData();
			fd.append('file', pFile);
			fd.append('imageWidth', pWidth);
			fd.append('imageHeight', pHeight);
			fd.append('siteID', pSiteID);
			fd.append('module', pModule);

			axios({
				method: 'post',
				url: `${this.$API_URL}/upload/${self.fileParams.deletePreviousSiteFile}`,
				data: fd,
				headers: { 'Content-Type': 'multipart/form-data' },
			})
				.then(async function (response) {
					//handle success
					self.isUploaded = true;

					// On supprime l'ancien fichier
					if (self.imageTabCopy[0].name && self.fileParams.deletePreviousSiteFile !== 'imagePagesPortail')
						await axios
							.delete(`${self.$API_URL}/sites/${self.fileParams.siteID}/image`, { data: { images: [ { name: self.imageTabCopy[0].name } ] } })
							.then(function (response) {
								// handle success
								// console.log('image supp')
							})
							.catch(function (error) {
								// handle error
								// console.log('image pas supp')
								console.log(error);
							})

					if (Array.isArray(self.fileParams.fileNodeValue)) {
					
						self.$set(self.fileParams.fileNodeValue, 0, { data_type: 'image', type: pFile.type, name: response.data.fileName, url: response.data.fileName })
					} else {
						self.$set(self.fileParams.fileNodeValue, "name", response.data.fileName)
						self.$set(self.fileParams.fileNodeValue, "type", pFile.type)
						self.$set(self.fileParams.fileNodeValue, "url", response.data.fileName);
					}

					self.$emit('updatedValue', self.fileParams.currentNode);
				})
				.catch(function (response) {
					//handle error
					self.isUploaded = false;

					console.log(response);
				});
		},
	},
};
</script>

<style lang="scss">
.custom-upload {
	display: flex;
	// justify-content: space-between;
	margin: 2rem 1rem;
	gap: 1rem;

	&__upload {
	}

	&__preview {
		display: flex;
		justify-content: center;
		align-items: center;
		background: #fff;
		aspect-ratio: 16 / 9;
		border-radius: 0.5rem;
		width: 400px;
		padding: 0.5rem;
		border: 1px solid #ccc;
		img,
		video {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

		svg {
			width: 100px;
			height: 100px;
		}
	}
}
</style>