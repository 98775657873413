<template>
    <div>
        <template v-for="(item, index) in items">
            <div class="divider" v-if="item.type === 'divider'" :key="`divider${index}`" />
            <menu-item v-else :key="index" v-bind="item" />
        </template>
    </div>
</template>

<script>
import MenuItem from './MenuItem.vue'
// import TextAlign from '@tiptap/extension-text-align'

export default {
    components: {
        MenuItem,
    },

    props: {
        editor: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            items: [
                {
                    icon: 'bold',
                    title: 'Bold',
                    action: () => {
                        this.editor.chain().focus().toggleBold().run()
                    },
                    isActive: () => this.editor.isActive('bold'),
                },
                {
                    icon: 'italic',
                    title: 'Italic',
                    action: () => this.editor.chain().focus().toggleItalic().run(),
                    isActive: () => this.editor.isActive('italic'),
                },
                {
                    icon: 'strikethrough',
                    title: 'Strike',
                    action: () => this.editor.chain().focus().toggleStrike().run(),
                    isActive: () => this.editor.isActive('strike'),
                },
                {
                    type: 'divider',
                },
                {
                    icon: 'CLearNode',
                    title: 'Clear Nodes',
                    action: () => {
                        let text = this.editor.getText();
                        this.editor.commands.setContent(text);
                    },
                    isActive: () => this.editor.isActive('heading', { level: 1 }),
                },
                // {
                //     icon: 'h-2',
                //     title: 'Heading 2',
                //     action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
                //     isActive: () => this.editor.isActive('heading', { level: 2 }),
                // },
                {
                    icon: 'paragraph',
                    title: 'Paragraph',
                    action: () => this.editor.chain().focus().setParagraph().run(),
                    isActive: () => this.editor.isActive('paragraph'),
                },
                {
                    type: 'divider',
                },
                {
                    icon: 'align-left',
                    title: 'AlignLeft',
                    action: () => this.editor.chain().focus().setTextAlign('left').run(),
                    isActive: () => this.editor.isActive({ textAlign: 'left' }),
                },
                {
                    icon: 'align-center',
                    title: 'AlignCenter',
                    action: () => this.editor.chain().focus().setTextAlign('center').run(),
                    isActive: () => this.editor.isActive({ textAlign: 'center' }),
                },
                {
                    icon: 'align-right',
                    title: 'AlignRight',
                    action: () => this.editor.chain().focus().setTextAlign('right').run(),
                    isActive: () => this.editor.isActive({ textAlign: 'right' }),
                },
                {
                    icon: 'align-justify',
                    title: 'AlignJustify',
                    action: () => this.editor.chain().focus().setTextAlign('justify').run(),
                    isActive: () => this.editor.isActive({ textAlign: 'justify' }),
                },
                // {
                //     icon: 'double-quotes-l',
                //     title: 'Blockquote',
                //     action: () => this.editor.chain().focus().toggleBlockquote().run(),
                //     // isActive: () => this.editor.isActive('blockquote'),
                // },
                // {
                //     icon: 'separator',
                //     title: 'Horizontal Rule',
                //     action: () => this.editor.chain().focus().setHorizontalRule().run(),
                // },
                {
                    type: 'divider',
                },
                {
                    icon: 'format-clear',
                    title: 'Clear Format',
                    action: () => this.editor.chain()
                        .focus()
                        .clearNodes()
                        .unsetAllMarks()
                        .run(),
                },
                {
                    type: 'divider',
                },
                {
                    icon: 'arrow-go-back-line',
                    title: 'Undo',
                    action: () => this.editor.chain().focus().undo().run(),
                },
                {
                    icon: 'arrow-go-forward-line',
                    title: 'Redo',
                    action: () => this.editor.chain().focus().redo().run(),
                },
            ],
        }
    },
}
</script>

<style lang="scss">
.divider {
    width: 2px;
    height: 1.25rem;
    background-color: rgba(#000, 0.1);
    margin-left: 0.5rem;
    margin-right: 0.75rem;
}
</style>